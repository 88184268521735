import React, { FC, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { isBrowser } from '@agria/utils';
import { ImageDataLike } from 'gatsby-plugin-image';
import { Container } from '../../container/Container';
import { LinkTo } from '../../linkTo/LinkTo';
import { Contact } from '../../icon/icons/Contact';
import { Typography } from '../../typography/Typography';
import { Logo } from '../../svg';
import { LogoSmall } from '../../svg/svgs/LogoSmall';
import { useBackgroundColor } from '../../../utils';
import tokens from './tokens';
import { Image } from '../../Image/Image';

// Interface
export interface SimpleHeaderProps {
  companyName?: string;
  contactNumber?: string;
  logoLink?: string;
  secondaryLogo?: ImageDataLike; // Logo in top right corner - this overides the contact number
  isBrochureware?: boolean;
}

// Styles
const StyledSimpleHeader = styled.header`
  ${({ theme: { space } }) => css`
    padding: ${space.fluidSmall} 0;
    ${useBackgroundColor(tokens.backgroundColor || 'utilityWhite')};
  `};
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledLogo = styled(LinkTo)`
  ${({ theme: { space, colors } }) => css`
    margin-right: ${space.medium};

    ${tokens.includeInverseFocus &&
    css`
      &:focus-visible {
        background-color: ${colors.brandTwo100};
        color: ${colors.utilityWhite};
        padding: 4px;
        margin: -4px;
      }
    `}
  `};
`;

const StyledLogoMobile = styled.span`
  ${({ theme: { media } }) => css`
    @media (min-width: ${media.medium}) {
      display: none;
    }
  `};
`;

const StyledLogoDesktop = styled.span`
  ${({ theme: { maxMedia } }) => css`
    @media (max-width: ${maxMedia.medium}) {
      display: none;
    }
  `};
`;

const StyledContact = styled.div<{ isVisible: boolean }>`
  ${({ theme: { space, maxMedia }, isVisible }) => css`
    display: ${isVisible ? 'flex' : 'none'};
    align-items: center;
    gap: ${space.xxSmall};

    @media (max-width: ${maxMedia.medium}) {
      display: none;
    }

    a {
      text-decoration: none;
    }
  `};
`;

const StyledContactNumberMobile = styled.a<{ isVisible: boolean }>`
  ${({ theme: { media }, isVisible }) => css`
    display: ${isVisible ? 'flex' : 'none'};
    border: none;
    padding: 0;
    @media (min-width: ${media.medium}) {
      display: none;
    }
  `};
`;

// Utility Function
const getUKDateTimeParts = () => {
  const formatter = new Intl.DateTimeFormat('en-GB', {
    timeZone: 'Europe/London',
    hour: 'numeric',
    hour12: false,
    weekday: 'long',
  });

  const parts = formatter.formatToParts(new Date());
  let UKHour;
  let UKDayName;

  parts.forEach((part) => {
    if (part.type === 'hour') {
      UKHour = parseInt(part.value, 10);
    }
    if (part.type === 'weekday') {
      UKDayName = part.value;
    }
  });

  // Map weekday names to numbers
  const weekdayMap = {
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
    Sunday: 7,
  };

  const UKDay = weekdayMap[UKDayName] || 1; // Default to 1 if not found

  return { UKHour, UKDay };
};

// Component
export const SimpleHeader: FC<SimpleHeaderProps> = ({
  companyName,
  contactNumber,
  logoLink = '/',
  secondaryLogo,
  isBrochureware,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (isBrowser) {
      const { UKHour, UKDay } = getUKDateTimeParts();

      // Business hours: Monday to Friday, 9 AM to 6 PM
      const isOpenState =
        UKDay >= 1 && UKDay <= 5 && UKHour >= 9 && UKHour < 18;

      setIsOpen(isOpenState);
    }
  }, []);

  const showContactNumber =
    (isOpen && tokens.includePhoneNumber) || (isOpen && isBrochureware);

  return (
    <StyledSimpleHeader isBrochureware={isBrochureware}>
      <StyledContainer>
        <StyledLogo
          to={logoLink}
          title={`${companyName} homepage`}
          aria-label={`${companyName} homepage`}
          state={{ reset: true }}
        >
          <StyledLogoMobile>
            <LogoSmall title={`${companyName} homepage`} aria-hidden="true" />
          </StyledLogoMobile>
          <StyledLogoDesktop>
            <Logo title={`${companyName} homepage`} aria-hidden="true" />
          </StyledLogoDesktop>
        </StyledLogo>
        {secondaryLogo && (
          <Image
            src={secondaryLogo.image.localFile}
            alt={secondaryLogo.image.altText}
          />
        )}
        {!secondaryLogo && showContactNumber && (
          <>
            <StyledContactNumberMobile
              aria-label={`Call us${contactNumber && ` on ${contactNumber}`}`}
              href={`tel:${contactNumber?.replace(/\s/g, '')}`}
              isVisible={isOpen}
            >
              <Contact title="Call us" />
            </StyledContactNumberMobile>
            <StyledContact isVisible={isOpen}>
              <Contact
                title="Call us"
                color={tokens.color}
                aria-hidden="true"
              />
              <Typography variant="bodyMediumBold">
                <a href={`tel:${contactNumber?.replace(/\s/g, '')}`}>
                  Call us on {contactNumber}
                </a>
              </Typography>
            </StyledContact>
          </>
        )}
      </StyledContainer>
    </StyledSimpleHeader>
  );
};

export default SimpleHeader;
